<template>
  <!-- 订单详情 退票单 -->
  <div>
    <Head :aircraftTicket="order" />
    <van-cell title="订单号" :value="order.orderSerialNo||order.orderNo" />
    <van-cell title="创建时间" v-if="order.createTime" :value="order.createTime| comverTime('YYYY-MM-DD hh:mm')" />
    <van-cell
      v-if="order.orderStatus"
      title="订单状态"
      :value="order.orderStatusText"
      :value-class="order.orderStatusText|statusColor"
    />
    <van-cell v-if="order.seatClass" title="座位类型" :value="order.seatClass" />
    <van-cell c-if title="出行人员">
      <template #default>
        <span v-for="(peo,pi) in peoList" :key="peo.passengerId">
          {{peo.passengerName}}
          <i v-if="(pi+1)<peoList.length"></i>
        </span>
      </template>
    </van-cell>
    <a :href="'tel:'+phone" v-if="order.corp">
      <van-cell title="服务商电话" v-show="phone">
        <template #title>
          客服
          <Logo :corp="order.corp" />
        </template>
        <template #default>
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </template>
      </van-cell>
    </a>
    <div class="gap"></div>
    <van-cell title="机票退还" :value="'￥'+Math.abs(order.orderAmount)" value-class="blue" />
    <van-cell
      v-if="Object.prototype.hasOwnProperty.call(peoList[0]&&peoList[0].insurance[0]&&peoList[0], 'insurance')"
      title="保险退还"
      :value="'￥'+peoList[0].insurance[0].premium"
      value-class="blue"
    />
    <div class="gap"></div>

    <van-steps
      direction="vertical"
      :active="0"
      :active-color="active==0?'red':active==1?'green':'orange'"
    >
      <van-step v-if="active==0">
        <p>退票失败，失败原因：{{order.orderStatusText}}。</p>
      </van-step>
      <van-step v-if="active==1">
        <p>退票成功，款项将在7个工作日内退回至您的付款帐户中。</p>
      </van-step>
      <van-step v-if="active>0">
        <p>退票正在处理，请耐心等待。</p>
        <br />
      </van-step>
      <van-step>
        <p>
          <span class="text-sm">{{order.createTime | comverTime('YYYY-MM-DD HH:mm')}}</span>
          <br />退票申请成功，请耐心等待。
          <br />
        </p>
        <p></p>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
import Head from "./components/InfoHead";
import { getFlightRefundOrderDetail } from "@/api/aircraft";
import { getStatusColor } from "@/services/aircraft.js";
import { fetchPhone } from "@/common/config.js";
import Logo from "@/components/Logo";
export default {
  components: { Head, Logo },
  data() {
    return {
      phone: "", // 服务商电话
      order: {}, // 订单
      peoList: [], // 人员
    };
  },
  computed: {
    // 退票状态
    active: function () {
      switch (parseInt(this.order.orderStatus)) {
        case 1:
        case 4:
        case 40:
        case 41:
        case 42:
          return 2; // 等待
        case 47:
        case 48:
        case 49:
        case 49:
        case 49:
          return 0; // 失败
        default:
          return 1; // 成功
      }
    },
  },
  methods: {
    // 获取初始化数据
    fetchData(query) {
      this.phone = fetchPhone(query.corp); // 更新服务商电话
      getFlightRefundOrderDetail({
        orderNo: query.orderNo,
        corp: query.corp,
      }).then((response) => {
        this.order = response.order;
        let peos = response.orderItem||[];
        peos.forEach((element) => {
          let id = element.identityNo;
          let insuranceList = response.insuranceOrderItems || []; // 防空值
          let res = insuranceList.filter((e) => {
            return e.identityNo == id;
          });
          element.insurance = res;
        });
        this.peoList = peos;
      });
    },
    // 判断状态属于退票中：2|失败：0|成功：1
    checkStatus(status) {},
  },
  activated() {
    this.peoList = [];
    this.order = {};
    // this.order = this.$route.query;
    this.fetchData(this.$route.query);
  },
  filters: {
    statusColor(data) {
      return getStatusColor(data);
    },
  },
};
</script>
<style lang="less" scoped>
p {
  margin: 1px 6px;
  padding-bottom: 1rem;
}
</style>